<script setup lang="ts">
import PayeeTableList from '/~/extensions/bank-file-upload/components/transactions/payee-list/payee-list.vue'
import BaseButton from '/~/components/base/button/base-button.vue'

const emit = defineEmits<{
  (event: 'hide'): void
}>()

function hide() {
  emit('hide')
}
</script>

<template>
  <div class="relative h-md overflow-hidden">
    <div class="absolute right-6 top-6">
      <base-button icon="heroicons/outline/x-mark" :size="32" @click="hide" />
    </div>
    <div class="p-5">
      <div class="flex flex-col items-center">
        <h2 class="w-72 text-center text-xl text-neutral-800">Payee details</h2>
      </div>
    </div>
    <payee-table-list class="mx-2 pb-20" />
  </div>
</template>
